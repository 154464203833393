import React from 'react';

import SEO from '../components/seo';
import Panel from '../components/panel';
import LinkOut from '../components/linkOut';
import Layout from '../components/layout';
import TopPanel from '../components/topPanel';
import ButtonLink from '../components/buttonLink';

import HorizontalTitle from '../components/horizontalTitle';
import VerticalButtonsContainer from '../components/verticalButtonsContainer';

import overview from '../images/logo.png';
import ucsdLogo from '../images/ucsdLogo.png';
import hpaLogo from '../images/hpaLogo.png';
import stanfordLogo from '../images/stanfordLogo.png';
import harvardLogo from '../images/harvardLogo.png';
import ucsfLogo from '../images/ucsfLogo.png';
import ubcLogo from '../images/ubcLogo.png';
import styles from './pageStyles/index.module.css';

const contrastBackgroundColor = 'rgba(65,182,255, 0.25)';
const contrastTextColor = 'black';

const IndexPage = () => (
  <Layout>
    <SEO title='MuSIC Project' />
    <TopPanel>
      <HorizontalTitle title='U-2 OS Multiscale Integrated Cell Map' />
      <VerticalButtonsContainer>
        <img src={overview} style={{ width: '20vw', minWidth: '10em' }} />
      </VerticalButtonsContainer>
      <VerticalButtonsContainer>
          <ButtonLink
            text='View on Cytoscape Web'
            link='https://idekerlab.ndexbio.org/cytoscape/0/networks/9a77e88b-1a06-11ef-bbe3-005056aecf54?filterEnabled=true&left=closed&right=open&bottom=open&activeTableBrowserTab=2'
            tooltip={false}
            newTab={true}
          />
                  <ButtonLink
            text='View on NDEx'
            link='https://idekerlab.ndexbio.org/viewer/networks/9a77e88b-1a06-11ef-bbe3-005056aecf54'
            tooltip={false}
           // tooltipPosition='bottom'
            //tooltipText='The team'
            newTab={true}
          />
                  <ButtonLink
            text='Browse Data'
            link='../u2os-cellmap-data/'
            tooltip={false}
           // tooltipPosition='bottom'
            //tooltipText='The team'
            newTab={false}
          />
                    <ButtonLink
            text='Browse Tools'
            link='../u2os-cellmap-tools'
            tooltip={false}
            newTab={false}
            //tooltipPosition='top'
           // tooltipText={'The github repository containing the MuSIC pipeline code'}
          />
          {/* <ButtonLink
            text='U2OS MuSIC Map (5254 proteins)'
            link='http://hiview.ucsd.edu/e289703e-2cb3-11ee-828b-005056aecf54?type=test&server=http://test.ndexbio.org'
            tooltip={false}
            //tooltipPosition='top'
            //tooltipText={'Browse the U2OS MuSIC Map on HiView' }
          /> */}
        </VerticalButtonsContainer>
    </TopPanel>
    <Panel>
      <div className={styles.logosContainer}>
        <div className={styles.logoGroup}>
          <LinkOut link='https://ucsd.edu/'>
            <img
              src={ucsdLogo}
              alt='UC San Diego logo'
              className={styles.logo}
              style={{ maxHeight: '1.5cm' }}
            />
          </LinkOut>
          <h3>
            <LinkOut link='https://ucsd.edu/'>UC San Diego</LinkOut>
          </h3>
          <h3>
            <LinkOut link='http://idekerlab.ucsd.edu/'>Ideker Lab</LinkOut>,{' '}
          </h3>
        </div>
        <div className={styles.logoGroup}>
          <LinkOut link='https://www.stanford.edu/'>
            <img src={stanfordLogo} alt='KTH Logo' className={styles.logo} />
          </LinkOut>
          <LinkOut link='https://www.proteinatlas.org/'>
            <img
              src={hpaLogo}
              alt='The Human Protein Atlas logo'
              className={styles.logo}
              style={{ maxHeight: '1.5cm' }}

            />
          </LinkOut>
          <h3>
            <LinkOut link='https://www.stanford.edu'>Stanford University</LinkOut>
          </h3>
          <h3>
            <LinkOut link='https://cellprofiling.org/'>Lundberg Lab</LinkOut>
          </h3>
        </div>
        </div>
      <div className={styles.logosContainer}>
        <div className={styles.logoGroup}>
          <LinkOut link='https://hms.harvard.edu/'>
            <img
              src={harvardLogo}
              alt='Harvard School of Medicine logo'
              className={styles.logo}
            />
          </LinkOut>
          <h3>
            <LinkOut link='https://hms.harvard.edu/'>
              Harvard Medical School
            </LinkOut>
          </h3>
          <h3>
            <LinkOut link='https://gygi.med.harvard.edu/'>Gygi Lab</LinkOut>,{' '}
            <LinkOut link='https://harper.hms.harvard.edu/'>Harper Lab</LinkOut>
          </h3>
        </div>
        <div className={styles.logoGroup}>
          <LinkOut link='https://www.ucsf.edu/'>
            <img
              src={ucsfLogo}
              className={styles.logo}
            />
          </LinkOut>
          <h3>
            <LinkOut link='https://www.ucsf.edu/'>
              UC San Francisco
            </LinkOut>
          </h3>
          <h3>
            <LinkOut link='https://salilab.org//'>Sali Lab</LinkOut>
          </h3>
        </div>
        <div className={styles.logoGroup}>
          <LinkOut link='https://www.ubc.ca/'>
            <img
              src={ubcLogo}
              className={styles.logo}
            />
          </LinkOut>
          <h3>
            <LinkOut link='https://www.ubc.ca/'>
              University of British Columbia
            </LinkOut>
          </h3>
          <h3>
            <LinkOut link='https://fosterlab.msl.ubc.ca/'>Foster Lab</LinkOut>
          </h3>
        </div>
        
      </div>
    </Panel>
      </Layout>
);

export default IndexPage;
